import detectBrowserLanguage from 'detect-browser-language';
export const numbersRegularExpression = /^[0-9]+$/;

export const EMAIL_SUPPORT = 'support@enter-business.com'
export const EMAIL_INFO = 'info@enter-business.com'
export const LINK_FACEBOOK = 'https://www.facebook.com/Enter-business-100422978809197'

export const PARAM_CARD_NMBRE = 'cardNmbre';
export const PARAM_CARD_NMBREFromCard = 'ncrd';
export const PARAM_CARD_CORECT_NOT_FOUND = 'cardnotfound'
export const PARAM_ID = 'id'
export const PARAM_RECOVER_UNIQUE_CODE = 'codeunique';
export const PARAM_EMAIL = 'email';
export const PARAM_NOT_YET = 'notyet';
export const PARAM_FIRST_NAME = 'firstname'

export const DOMAIN_API = () => {
  const realUrl = 'https://api.enter-business.com'
  let domain = '';
  (process.env.NODE_ENV === 'development')?domain = realUrl:domain = realUrl;
  return domain
};

export const URL_LOGIN = `${DOMAIN_API()}/accounts/signin`;
export const URL_GETALL_PARTNER_without_tocken="https://enter-business.com/new/partners";
export const URL_ASK_RECOVERY_LINK = `${DOMAIN_API()}/accounts/askrecoverylink`;
export const URL_RECOVER_ACCOUNT = `${DOMAIN_API()}/accounts/recoveraccount`;
export const URL_CHANGE_PASSWORD = `${DOMAIN_API()}/accounts/changepassword`;
export const URL_MODIFY_OPTION_USER=`https://enter-business.com/new/addOption`;
export const URL_ADD_ADVICE=`https://enter-business.com/new/add_advice`;
export const URL_SEND_USER_TO_API=`https://enter-business.com/new/add_api`;
export const URL_SEND_MAIL_USER=`https://enter-business.com/new/send_mail`;
export const URL_GETALL_VAL=`https://enter-business.com/new/get_adv_val`;
export const URL_GETALL_ADVANTAGE_PARTNERS=`https://enter-business.com/new/advantage_partners`;
export const URL_DELETE_USER=`https://enter-business.com/new/user_delete`;
export const URL_REGISTER_USER = `${DOMAIN_API()}/user-contact`;
export const URL_SIGNUP_USER = `${DOMAIN_API()}/user`;
export const URL_IS_AUTH = `${DOMAIN_API()}/auth/isauth`;
export const URL_GETALL_ADVANTAGES = `${DOMAIN_API()}/advantages`;
export const URL_GETALL_ADVICEES = `${DOMAIN_API()}/advices`;
export const URL_GETALL_FOLDERS = `${DOMAIN_API()}/folders`;
export const URL_GETALL_NEWS = `${DOMAIN_API()}/news`;
export const URL_GETALL_VIDEOS = `${DOMAIN_API()}/videos`;
export const URL_GETALL = `${DOMAIN_API()}/user/getall`;
export const URL_GETALL_FILTERER_BY_KEYPHRASE = `${DOMAIN_API()}/user/getallbysearch`;

export const URL_GET_ACCOUNT_MESSAGES = `${DOMAIN_API()}/messages/`;

export const URL_GETONE_ADVICE = `${DOMAIN_API()}/advices/getonebyid`;
export const URL_GETONE_ADVANTAGE = `${DOMAIN_API()}/advantages/getonebyid`;
export const URL_GETSUPERPARTNER_DATA_ADVANTAGE = `${DOMAIN_API()}/advantages/getpartnersuper`;
export const URL_GET_USER_FULL = `${DOMAIN_API()}/user/oneFull`;
export const URL_MODIFY_USER = `${DOMAIN_API()}/user`;
export const URL_ADD_AVATARIMG_USER = `${DOMAIN_API()}/user/addavatarimg`;
export const URL_DELETE_AVATARIMG_USER = `${DOMAIN_API()}/user/deleteavatarimg`;
export const URL_CHECK_AVATARIM_VALIDITY_USER = `${DOMAIN_API()}/user/checkAvatarImgValidity`;
export const URL_TAKE_ADVANTAGE_USER = `${DOMAIN_API()}/user/takeadvantage`;
export const URL_CREATELIST_OF_PARTNER_USER = `${DOMAIN_API()}/user/createListOfPartner`;
export const URL_CREATE_LISTEOFINTEREST_USER = `${DOMAIN_API()}/user/createListOfinterest`;

export const URL_GETONE_BCEDATA_BYNCRDNUMBER = `${DOMAIN_API()}/user-data-bce/getbyncrdnum`;
export const URL_GET_NACEBELCODES = `${DOMAIN_API()}/nacebel-codes`;

export const URL_GETONE_CONTACT_BYEMAIL = `${DOMAIN_API()}/user-contact/getonebyemail`;

export const URL_GETALL_PARTNER_SUPER = `${DOMAIN_API()}/partner-super/getall`;
export const URL_GETALL_PARTNER_SUPER_FILTERED = `${DOMAIN_API()}/partner-super/getallfiltered`;
export const URL_GETONE_PARTNERSUPER_FILTERED =`${DOMAIN_API()}/partner-super/getonebyidfiltered`;
export const URL_GETONE_PARTNERSUPER =`${DOMAIN_API()}/partner-super/getonebyid`;
export const URL_GET_MAINPARTNERSUPER_DATA = `${DOMAIN_API()}/partner/getpartnersuperdata`;
export const URL_GETALL_INTEREST_AVAILABLE = `${DOMAIN_API()}/admin/ListOfinterest`;

export const URL_GETALL_INTEREST = `${DOMAIN_API()}/interests/`;
export const URL_USER_CREATE_LIST_OF_INTEREST = `${DOMAIN_API()}/user/createlistofinterest`;

export const AUTH_TOKEN_KEY = 'authToken';
export const KEY_EMAIL = 'email';
export const KEY_PASSWORD_TEMP = '33tx8e15z';
export const KEY_LANGUAGE_BROWSER = 'languageBrowser';
export const KEY_LANGUAGE_USER = 'languageUser';

export const ALERT_MSG_ERROR = () => {
  if (typeof window !== undefined) {
    if (getUserLanguage() === FR) {
      return 'Erreur'
    }else {
     return 'Fout'
    }
  }
  return 'Erroriro'
}
export const ALERT_MSG_INFO = 'Info'

export const FR = 'FR';
export const NL = 'NL';
export const FEMALE = 'F';
export const MAN = 'M';

export const NCRDNUMBER_LENGTH = 11;

export const BCE_JUR_FORM_NAME_NV = {
  fromBceName: 'Société anonyme',
  abreviationNL: 'Naamloze Vennootschap'
};
export const BCE_JUR_FORM_NAME_BVBA = {
  fromBceName: 'Société à responsabilité limitée',
  abreviationNL: 'Besloten Vennootschap'
};
export const BCE_JUR_FORM_NAME_CV = {
  fromBceName: 'Société coopérative',
  abreviationNL: 'Coöperatieve Vennootschap'
};
export const BCE_JUR_FORM_NAME_Eenmanszaak = {
  fromBceName: 'Entreprise d’une personne',
  abreviationNL: 'Eenmanszaak'
};
export const BCE_JUR_FORM_NAME_VZW ={
  fromBceName: 'Association sans but lucratif',
  abreviationNL: 'Vennootschap Zonder Winst'
};
export const BCE_JUR_FORM_NAME_Overige_rechtsvormen ={
  fromBceName: 'Autres formes juridiques',
  abreviationNL: 'Overige_rechtsvormen'
};

/*
* */
export const BCE_JUR_FORM_NAME_Commanditaire_Vennootschap ={
  fromBceName: 'Société en commandite',
  abreviationNL: 'Commanditaire Vennootschap'
};

export const BCE_JUR_FORM_NAME_Maatschap_zonder_rechtspersoonlijkheid ={
  fromBceName: 'Société simple sans personnalité juridique',
  abreviationNL: 'Maatschap zonder rechtspersoonlijkheid'
};

export const BCE_JUR_FORM_NAME_Vennootschap_Onder_Firma ={
  fromBceName: 'Société en nom collectif',
  abreviationNL: 'Vennootschap Onder Firma'
};



export const getDateFormated = () => {
  const date = new Date()
  let month = ''
  const monthNumbre = date.getMonth();
  if (monthNumbre === 0) month = 'JAN'
  if (monthNumbre === 1) month = 'FEB'
  if (monthNumbre === 2) month = 'MAR'
  if (monthNumbre === 3) month = 'APR'
  if (monthNumbre === 4) month = 'MAY'
  if (monthNumbre === 5) month = 'JUN'
  if (monthNumbre === 6) month = 'JULY'
  if (monthNumbre === 7) month = 'AUG'
  if (monthNumbre === 8) month = 'SEPT'
  if (monthNumbre === 9) month = 'OCT'
  if (monthNumbre === 10) month = 'NOV'
  if (monthNumbre === 11) month = 'DEC'
  //return `${month} ${date.getDate()}, ${date.getFullYear()}`
  return `${date.getDate().toLocaleString('en-US',{minimumIntegerDigits: 2,useGrouping: false})}.${(date.getMonth()+1).toLocaleString('en-US',{minimumIntegerDigits: 2,useGrouping: false})}.${date.getFullYear()}`
}

export const getFormatedDate = (date) => {
  let month = ''
  const monthNumbre = date.getMonth();
  if (monthNumbre === 0) month = 'JAN'
  if (monthNumbre === 1) month = 'FEB'
  if (monthNumbre === 2) month = 'MAR'
  if (monthNumbre === 3) month = 'APR'
  if (monthNumbre === 4) month = 'MAY'
  if (monthNumbre === 5) month = 'JUN'
  if (monthNumbre === 6) month = 'JULY'
  if (monthNumbre === 7) month = 'AUG'
  if (monthNumbre === 8) month = 'SEPT'
  if (monthNumbre === 9) month = 'OCT'
  if (monthNumbre === 10) month = 'NOV'
  if (monthNumbre === 11) month = 'DEC'
  //return `${month} ${date.getDate()}, ${date.getFullYear()}`
  return `${date.getDate().toLocaleString('en-US',{minimumIntegerDigits: 2,useGrouping: false})}.${(date.getMonth()+1).toLocaleString('en-US',{minimumIntegerDigits: 2,useGrouping: false})}.${date.getFullYear()}`
}

export const firstLetterCapitalize = (string) => {
  const newString = string.toLowerCase();
  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

export const convertddmmyyyyTommddyyyy = (date) => {
  const dateArray = date.split('/');
  if (dateArray.length > 1) {
    return `${(dateArray[1]).toLocaleString('en-US',{minimumIntegerDigits: 2,useGrouping: false})}/${(dateArray[0]).toLocaleString('en-US',{minimumIntegerDigits: 2,useGrouping: false})}/${dateArray[2]}`;
  }else return date
}

export const convertmmddyyyyToddmmyyyy = (date) => {
  const dateArray = date.split('/');
  if (dateArray.length > 1) {
    return `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
  }else return date
}

export const decreaseMonthsFromCurrentDate = (monthToDecrease) => {
  const currentDate = new Date();
  const targetTime = currentDate.getTime() - (2.628*1000000000*monthToDecrease);
  const targetDate = new Date(targetTime);
  const formatedDDMMYYYYDate = `${targetDate.getDate().toLocaleString('en-US',{minimumIntegerDigits: 2,useGrouping: false})}/${(targetDate.getMonth()+1).toLocaleString('en-US',{minimumIntegerDigits: 2,useGrouping: false})}/${targetDate.getFullYear()}`;
  return formatedDDMMYYYYDate
}

export const translate = (string,isFirstUprCase,noFirstLowerCaseChange) => {
  if (typeof window !== `undefined` && string !== undefined) {
    if (string) {
      //GET language
      let language = NL;
      if (localStorage.getItem(KEY_LANGUAGE_USER)) {
        language = localStorage.getItem(KEY_LANGUAGE_USER);
      }else {
        const browserLang = detectBrowserLanguage();
        if (browserLang === undefined || browserLang === null) console.log('browser language not found');
        if (browserLang.includes('fr')) {
          localStorage.setItem(KEY_LANGUAGE_BROWSER,FR);
          language = FR;
        }
        if (browserLang.includes('nl')) {
          localStorage.setItem(KEY_LANGUAGE_BROWSER,NL);
          language = NL;
        }
      }

      //Translate
      let isUprCaseTemp = true
      let noFirstLowerCaseChangeTemp = false
      if (isFirstUprCase !== undefined) isUprCaseTemp = isFirstUprCase;
      if (noFirstLowerCaseChange !== undefined) noFirstLowerCaseChangeTemp = noFirstLowerCaseChange;

      const tr = require('./translations/tr');

      //get translation
      let translation = string;
      if (tr[string]) {
        translation = tr[string][language]
      }else console.log('Error translation of '+string+' not found');
      if (isUprCaseTemp && (translation !== undefined) && !noFirstLowerCaseChangeTemp && (translation !== '') && (translation[0])) translation = translation[0].toUpperCase() + translation.slice(1);
      return translation
    }else {
      return '';
    }
  }
  return string
};

export const getUserLanguage = () => {
  if (typeof window !== `undefined`) {
    const userLanguage = localStorage.getItem(KEY_LANGUAGE_USER);
    if (userLanguage) return userLanguage;
    const browserLanguage = localStorage.getItem(KEY_LANGUAGE_BROWSER);
    if (browserLanguage) return browserLanguage;
    const browserLang = detectBrowserLanguage();
    if (browserLang === undefined || browserLang === null) return NL;
    if (browserLang.includes('fr')) {
      return FR;
    }
    if (browserLang.includes('nl')) {
      return NL;
    }
    return NL;
  }
  return NL;
}

export const COLOR_GREEN = 'green';
export const COLOR_BLUE = 'blue';
export const COLOR_RED = 'red';

export const TYPE_ALL = 'Alles';
export const TYPE_FOLDER = 'Dossier';
export const TYPE_ADVANTAGE = 'Deal';
export const TYPE_ACTU = 'Nieuws';


export const ROLE_SUPERADMIN = 'superadmin';
export const ROLE_ADMIN = 'admin';
export const ROLE_USER = 'user';
export const ROLE_PARTNERCOLLAB = 'partnercollab';
export const ROLE_PARTNERSUPER = 'partnersuper';



//REQUESTS

